export class Utils {
    public static GetHeaders(token:string, contentType:string = ""): HeadersInit {
        if ( contentType.length > 0 ) {
            const headers: HeadersInit = {
                'Content-Type': contentType,
                'Authorization': `Bearer ${token}`
            }
            return headers;
        }

        const headers: HeadersInit = {
            'Authorization': `Bearer ${token}`
        }
        return headers;
    }

    public static GetRequest(token:string, contentType:string = ""): RequestInit {
        let headers = this.GetHeaders(token, contentType);
        const requestInit: RequestInit = {
            method: 'GET',
            headers,
        };
        return requestInit;
    }

    public static PostRequest(token:string, data:Map<string, string>):RequestInit {
        const headers: HeadersInit = Utils.GetHeaders(token);
        let formData = new FormData();

        data.forEach((value:string, key:string) => {
           formData.append(key, value);
        });

        const requestInit: RequestInit = {
            method: 'POST',
            headers:headers,
            body:formData
        };
        return requestInit;
    }
}