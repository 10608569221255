export const getLocalizationResources = () => {
    return {
        en: {
            translation: {
                "YourPoints": "Points: {{points}}",
                "Lives": "Lives",
                "Bid": "Bid",
                "CurrentQuestion": "Question: ",
                "AnswerOptions": "Guess the hidden number",
            }
        },
        ru: {
            translation: {
                "YourPoints": "Ваши очки: {{points}}",
                "Lives": "Жизни",
                "Bid": "Ставка",
                "CurrentQuestion": "Вопрос: ",
                "AnswerOptions": "Угадайте скрытый интерес",
            }
        }
    };
}

export default getLocalizationResources;