import * as signalR from "@microsoft/signalr";
import {HttpTransportType, HubConnection} from "@microsoft/signalr";


class ViewerWebSocketHub extends EventTarget {
    private connection:HubConnection;
    public static ServerInformedAboutUpdate:string = "ServerInformedAboutUpdate";
    public static ServerInformedAboutNewGame:string = "ServerInformedAboutNewGame";

    constructor(url:string, token:string) {
        super();
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(url + "/viewerHub",
                {
                    accessTokenFactory: () => token,
                    transport: HttpTransportType.WebSockets
                }
            ).build();
    }

    connect():void {
        this.connection.on("messageReceived", (username: string, message: string) => {
            console.log("RSignal " + username + ": " + message);
        });
        this.connection.on("UpdateGameInfo", () => {
            console.log("RSignal: Server says game has updated");
            this.dispatchEvent(new Event(ViewerWebSocketHub.ServerInformedAboutUpdate))
        });
        this.connection.on("NewGameInfo", () => {
            console.log("RSignal: Server says new game has been started");
            this.dispatchEvent(new Event(ViewerWebSocketHub.ServerInformedAboutNewGame))
        });
        this.connection.onclose(() => {
            console.log("RSignal connection closed");
        });

        this.connection.start().catch((err) => console.log("RSignal error: " + err));
    }
}

export default ViewerWebSocketHub;