export enum BackendConfig {
    Local,
    Remote,
    RemoteSecure
};

export class BackendInfo {
    public httpURL:string = "";
    public httpPort: number = 0;

    public wsURL:string = "";
    public wsPort: number = 0;

    constructor(url:string, port:number, wsURL:string, wsPort:number) {
        this.httpURL = url;
        this.httpPort = port;

        this.wsURL = wsURL;
        this.wsPort = wsPort;
    }

    toFinalHttpURL = ():string => this.httpURL + ":" + this.httpPort.toString();
    toFinalWsURL = ():string => this.wsURL + ":" + this.wsPort.toString() + "/ws";
}

export const GetBackendURL = (backend:BackendConfig):BackendInfo => {
    if ( backend === BackendConfig.Remote) {
        return new BackendInfo ( "http://actionstream.xyz",  2244,
                            "ws://actionstream.xyz", 2244);
    }
    else if ( backend === BackendConfig.RemoteSecure ) {
        return new BackendInfo ( "https://actionstream.xyz",  2243,
                            "wss://actionstream.xyz", 2243);
    }
    return new BackendInfo("http://localhost", 5002,
                        "ws://localhost/ws", 5002);
};

