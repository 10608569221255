import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {createStore, applyMiddleware, Store} from "redux";
import thunk from "redux-thunk"
import reducer from "./store/reducer"
import AppState, {DispatchType} from "./store/storeBaseTypes";
import "./viewer.css"



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store:Store<AppState> & {
    dispatch: DispatchType
} = createStore(reducer, applyMiddleware(thunk))

root.render(
    /*<React.StrictMode>-->*/
        <Provider store={store}>
            <App></App>
        </Provider>
    /*</React.StrictMode>*/
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
