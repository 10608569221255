import React, {useEffect, useState} from 'react'

const ViewerAnswerButtonComponent = (p:{text:string, isSelected:boolean, isDisabled:boolean, onClick:() => void}) => {
    const [, setIsClicked] = useState(false);
    const [, setIsOver] = useState(false);

    let handleWindowUp = (e:any) => setIsClicked(false);

    useEffect(() => {
        window.addEventListener("mouseup", handleWindowUp);

        return function cleanup() {
            window.removeEventListener("mouseup", handleWindowUp);
        }
    }, []);

    let handleButtonDown = () => setIsClicked(true);
    let handleButtonUp = () => setIsClicked(false);
    let handleMouseEnter= () => setIsOver(true);
    let handleMouseLeave= () => setIsOver(false);

    let buttonClassName = "";
    let buttonTextClassName = "";
    if ( p.isSelected ) {
        buttonClassName = "button_selected_answer";
        buttonTextClassName = "button_selected_answer_text";
    }else if ( p.isDisabled) {
        buttonClassName = "button_inactive";
        buttonTextClassName = "button_select_text";
    } else {
        buttonClassName = "button_select";
        buttonTextClassName = "button_select_text";
    }

    buttonClassName = "button_common " + buttonClassName;
    buttonTextClassName = "button_select_text_common " + buttonTextClassName;


    return (<td width="20%" height="100%"
                    onMouseDown={handleButtonDown}
                    onMouseUp={handleButtonUp}
                    onMouseEnter ={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                        if (!p.isDisabled && !p.isSelected)
                        {
                            p.onClick()
                        }
                    }}>
                    <div className={buttonClassName}>
                        <div className={buttonTextClassName}>{p.text}</div>
                    </div>
                </td>)
}

export default ViewerAnswerButtonComponent;