import {AppAction, AppState} from "./storeBaseTypes";
import * as actionTypes from "./actionTypes";
import AppViewState from "./AppViewState";
import {BackendInfo} from "../configs/BackendConfig";
import {TwitchCredentials} from "../configs/TwitchCredentials";

const initialState: AppState = {
    twitch: null,
    twitchInfo: null,
    backendInfo: null,
    gameId: "",
    appViewState: AppViewState.StartUp,
    asyncOperationInProgress:false,
    serverInformedAboutUpdate:false
}

const reducer = (
    state: AppState = initialState,
    action: AppAction
): AppState => {
    switch (action.type) {
        case actionTypes.SET_TWITCH_CONTROL_OBJECT:
            return {
                ...state,
                appViewState: AppViewState.TwitchControlObjSet,
                twitch: action.companionObj
            }
        case actionTypes.SET_TWITCH_CREDENTIALS:
            let twitchCredentials = new TwitchCredentials(action.args.get("twitchId") as string,
                                                        action.args.get("twitchToken") as string,
                                                        action.args.get("streamerId") as string)
            return {
                ...state,
                appViewState: AppViewState.TwitchCredentialsSet,
                twitchInfo: twitchCredentials
            }
        case actionTypes.SET_BACKEND_INFO:
            let backendInfo = action.companionObj as BackendInfo;
            return {
                ...state,
                appViewState: AppViewState.BackendSet,
                backendInfo: backendInfo
            }
        case actionTypes.SET_GAME_ID:
            return {
                ...state,
                appViewState: AppViewState.GameIdSet,
                gameId: action.args.get("gameId") as string
            }
        case actionTypes.SET_APP_VIEW_STATE:
            return {
                ...state,
                appViewState: AppViewState[action.args.get("appViewState") as keyof typeof AppViewState]
            }
        case actionTypes.SET_TEST_VIEW_STATE:
            return {
                ...state,
                appViewState: AppViewState.Test
            }
        case actionTypes.SET_GLOBAL_ASYNC_OP_IN_PROGRESS:
            return {
                ...state,
                asyncOperationInProgress: action.args.get("asyncOpInProgress") === "true"
            }
        case actionTypes.SEVER_INFORMED_ABOUT_UPDATE:
            return {
                ...state,
                serverInformedAboutUpdate:true
            }
        case actionTypes.STARTED_FETCHING_SERVER_UPDATE:
            return {
                ...state,
                serverInformedAboutUpdate:false
            }
    }

    return state;
}

export default reducer