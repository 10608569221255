import React, {useEffect} from 'react';
import './App.css';
import {useDispatch, useSelector, useStore} from 'react-redux'
import {
  serverInformedAboutUpdate,
  setBackendInfo,
  setGameId,
  setTwitchControlObject,
  setTwitchCredentials
} from "./store/actionCreators";
import Viewer from "./Viewer";
import {BackendConfig, GetBackendURL} from "./configs/BackendConfig";
import AppState from "./store/storeBaseTypes";
import AppViewState from "./store/AppViewState";
import ViewerWebSocketHub from "./ViewerWebSocketHub";
import {Utils} from "./Utils";
import getLocalizationResources from "./Localization";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const App = () => {

  const dispatch = useDispatch()
  const store = useStore();

  let viewerWebSocketHub:ViewerWebSocketHub;

  const appViewStateSelector = useSelector( (x: AppState) => x.appViewState);

  useEffect(() => {
    const onPageLoad = () => {

      let resources = getLocalizationResources();
      i18n.use(initReactI18next) // passes i18n down to react-i18next
          .init({
            resources,
            lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
            // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
            // if you're using a language detector, do not define the lng option

            interpolation: {
              escapeValue: false // react already safes from xss
            }
          }).then(() =>
      {
        console.log("Client: setting backend info");
        let appState = store.getState() as AppState;
        if ( appState.appViewState === AppViewState.StartUp) {
          dispatch(setBackendInfo(GetBackendURL(BackendConfig.RemoteSecure)))
        }

        //dispatch(setTestViewState(AppViewState.Test));

      });

    }

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      return onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }

  });

  let appState = store.getState() as AppState;

  let fetchGameInfo = (token:string) => {
    if (appState.backendInfo === null || appState.twitchInfo === null ) {
      return;
    }

    const url = appState.backendInfo.toFinalHttpURL() + "/api/check_game?";

    fetch(url + new URLSearchParams({
      streamerId:appState.twitchInfo?.streamerId
    }), Utils.GetRequest(token, 'application/json')).then( response =>
    {
      if ( response.status === 200 ){
        response.text().then(gameId =>
        {
          console.log("Game exists. Game id " + gameId);
          dispatch(setGameId(gameId))
        })
      }
    });
  };

  if ( appState.appViewState === AppViewState.Test) {
    return (<Viewer userScore={1000} currentQuestion={"This is a test question!"} currencyCode={"руб."}/>)
  }

  if ( appViewStateSelector === AppViewState.BackendSet) {
    let twitch = (window as any).Twitch ? (window as any).Twitch.ext : null;
    dispatch(setTwitchControlObject(twitch));
    return (<text/>);
  }

  if ( appViewStateSelector === AppViewState.TwitchControlObjSet) {
    let appStateTwitch = appState.twitch;
    appStateTwitch.actions.requestIdShare();
    appStateTwitch.onAuthorized((auth:{token:string, userId:string, channelId:string})=>{
      console.log("Twitch response: " + JSON.stringify(auth));
      console.log("Twitch credentials: " + auth.token + ". UserID: " + auth.userId + ". ChannelID: " + auth.channelId);
      console.log("Twitch. Are bits enabled? " + appStateTwitch.features.isBitsEnabled);
      dispatch(setTwitchCredentials(auth.userId, auth.token, auth.channelId));
      //Test getProducts
      appStateTwitch.bits.getProducts().then((products:object) => {
        console.log(products); // [ { sku: 'abc123', cost: { type: 'bits', amount: '10' } } ]
      });
      //TODO: Temporary set onAuthorized to null to avoid double firing
      appStateTwitch.onAuthorized((auth:{token:string, userId:string, channelId:string}) => {});
    })
    return (<text/>);
  }

  if ( appViewStateSelector === AppViewState.TwitchCredentialsSet &&
       appState.backendInfo != null && appState.twitchInfo != null )
  {
    console.log("Client: twitch credentials set");
    let token = appState.twitchInfo?.token;
    viewerWebSocketHub = new ViewerWebSocketHub(appState.backendInfo.toFinalHttpURL(), token);
    viewerWebSocketHub.addEventListener( ViewerWebSocketHub.ServerInformedAboutUpdate, (e) =>
    {
      dispatch(serverInformedAboutUpdate())
    });
    viewerWebSocketHub.addEventListener( ViewerWebSocketHub.ServerInformedAboutNewGame, (e) =>
    {
      fetchGameInfo(token);
    });
    viewerWebSocketHub.connect();

    fetchGameInfo(token);

    return (<text/>);
  }

  if ( appState.appViewState === AppViewState.GameIdSet) {
    return (<Viewer userScore={1000} currentQuestion={null} currencyCode={"руб."}/>)
  }

  return (<text/>);
}

export default App;
