import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux'
import ViewerAnswerButtonComponent from "./components/ViewerAnswerButtonComponent";
import AppState from "./store/storeBaseTypes";
//import {useInterval} from "./useInterval";
import {PlayerStatusResponse} from "./data/PlayerStatusResponse";
import {setAsyncOpInProgress, startedFetchingUpdateFromServer} from "./store/actionCreators";
import LoadingSpinner from "./LoadingSpinner";
import parse from 'html-react-parser';
import {Utils} from "./Utils";
import {t} from "i18next";

const Viewer = (p:{userScore:number | null, currentQuestion:string | null, currencyCode:string}) => {
    const [getSelectedIndexButton, setSelectedIndexButton] = useState(-1);
    const [userScoreValue, setUserScoreValue] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [answerGiven, setAnswerGiven] = useState(false);

    const store = useStore();
    const dispatch = useDispatch()

    const twitchInfoSelector = useSelector( (x: AppState) => x.twitchInfo);
    const backendInfoSelector = useSelector( (x: AppState) => x.backendInfo);
    const gameIdSelector = useSelector( (x: AppState) => x.gameId as string);
    const isLoadingSelector = useSelector( (x: AppState) => x.asyncOperationInProgress)
    const serverInformedAboutUpdate = useSelector( (x: AppState) => x.serverInformedAboutUpdate)

    const backendURL = backendInfoSelector?.toFinalHttpURL();
    const twitchToken = twitchInfoSelector?.token as string;

    const updateInfo = async () => {
        console.log("Client: twitch update status");
        let appState = store.getState() as AppState;
        if (appState.backendInfo != null) {
            const url = appState.backendInfo.toFinalHttpURL() + "/api/get_status?";
            let response = await fetch(url + new URLSearchParams({
                "gameId": gameIdSelector as string
            }), Utils.GetRequest(twitchInfoSelector?.token as string, 'application/json'))

            let playerStatusResponse: PlayerStatusResponse = JSON.parse(await response.text());
            setAnswerGiven(playerStatusResponse.HasViewerAnswered);
            setUserScoreValue(playerStatusResponse.Points);
            setCurrentQuestion(playerStatusResponse.CurrentGameQuestionText)
            if ( !playerStatusResponse.HasViewerAnswered ) {
                setSelectedIndexButton(-1);
            }

            //console.log("State updated " + JSON.stringify(playerStatusResponse));
        }
    }

    useEffect(() => {
        //This block is used to debug Viewer interface when we pass local data instead of remote data
        console.log("useEffect " + p.userScore);
        if ( p.userScore !== null ) {
            setUserScoreValue(p.userScore);
        }

        if ( p.currentQuestion !== null ) {
            setCurrentQuestion(p.currentQuestion);
        }
        //#end debug block

        updateInfo().then(r => console.log(r)).then( r => {
            if (twitchInfoSelector === null)
                return;

            let map = new Map<string, string>();
            map.set("streamerId", twitchInfoSelector.streamerId);

            const opts = Utils.PostRequest(twitchInfoSelector.token, map);
            fetch(backendURL + "/api/enter_game",  opts);
        });
    }, [gameIdSelector]);// eslint-disable-line

    const onAnswerSelectedButtonClicked = async (id:number) => {
        setSelectedIndexButton(id);

        let map = new Map<string, string>();
        map.set("gameId", gameIdSelector);
        map.set("choiceId", id.toString());

        const opts = Utils.PostRequest(twitchToken, map);

        await fetch(backendURL + "/api/make_choice",  opts);

        setAnswerGiven(true);
    }

    const applyPurchase = async (transaction:string) => {
        let map = new Map<string, string>();
        map.set("gameId", gameIdSelector);
        map.set("transaction", transaction);

        const opts = Utils.PostRequest(twitchToken, map);

        let result = await fetch(backendURL + "/api/apply_purchase", opts);

        console.log("applyPurchase result " + await result.text());

        updateInfo();
    }

    const onSupportButtonClicked = async (productSku:number) => {
        dispatch(setAsyncOpInProgress(true));

        let appState = store.getState() as AppState;
        let bits = appState.twitch.bits;
        bits.onTransactionComplete( (x:any) => {
            console.log("onTransactionComplete " + JSON.stringify(x));
            applyPurchase(JSON.stringify(x));
            dispatch(setAsyncOpInProgress(false));
        });
        bits.onTransactionCancelled( () => {
            console.log("onTransactionCancelled ");
            dispatch(setAsyncOpInProgress(false));
        });

        appState.twitch.bits.useBits(productSku.toString());

        //dispatch(setAsyncOpInProgress(false))
        console.log("onSupportButtonClicked " + productSku)
    };

    if (serverInformedAboutUpdate) {
        updateInfo()
        dispatch(startedFetchingUpdateFromServer());
    }

    let answersButtonPrep:ReactElement[] = new Array(5);
    for (let i = 1; i < 6; ++i) {
        answersButtonPrep[i -1] = <ViewerAnswerButtonComponent text={i.toString()}
                                                          isDisabled={answerGiven && getSelectedIndexButton !== i}
                                                          isSelected={getSelectedIndexButton === i}
                                                          key={i.toString()}
                                                          onClick={() => onAnswerSelectedButtonClicked(i)}/>
    }

    return (<div className="Viewer">
        {isLoadingSelector ? <LoadingSpinner></LoadingSpinner> : <div/>}
        <div className="top_points">
            <table width="100%;" cellPadding="0" cellSpacing="3">
                <tbody>
                <tr>
                    <td>
                        <div className="top_points_text">{t("YourPoints", {points: userScoreValue.toString()})}</div>
                    </td>
                    <td>
                        <div className="top_points_text">{t("Lives") + ":"}</div>
                    </td>
                    <td className="top_points_heart" width="8%"/>
                    <td className="top_points_heart" width="8%"/>
                    <td className="top_points_heart" width="8%"/>
                </tr>
                </tbody>
            </table>
        </div>

        <div className="bid_frame">
            <div className="question_text">{t("CurrentQuestion")}{parse(currentQuestion)}</div>
    </div>
    <div className="answers_block">
        <div className="top_bid_text">{t("AnswerOptions")}</div>

        <div className="table_padding">
            <table cellPadding="0" cellSpacing="0">
                <tbody>
                <tr>
                    {answersButtonPrep}
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div className="donate_frame">
        <div className="donate_table_padding">
            <table width="100%;" cellPadding="0" cellSpacing="3">
                <tbody>
                <tr>
                    <td className="donate_table_block" width="10%"/>
                    <td width="5%">
                        Buy
                    </td>
                    <td className="donate_table_block_heart" width="8%"/>
                    <td className="donate_table_block" width="10%"/>
                    <td className="donate_table_block" width="15%">
                        <div className="donate_button" onClick={() => onSupportButtonClicked(0)}>
                            <table width="100%;" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td className="donate_button_text"> 100</td>
                                        <td width="20%"></td>
                                        <td className="donate_button_bits_icon" width="50%"/>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                    <td className="donate_table_block" width="10%"/>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>)
}

export default Viewer;