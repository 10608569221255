import {AppAction} from "./storeBaseTypes";
import * as actionTypes from "./actionTypes";
import AppViewState from "./AppViewState";
import {BackendInfo} from "../configs/BackendConfig";


export function setTwitchControlObject(twitchControlObject:any):AppAction {
    const action: AppAction = {
        type: actionTypes.SET_TWITCH_CONTROL_OBJECT,
        args: new Map<string, string>(),
        companionObj: twitchControlObject
    }
    return action;
}

export function setTwitchCredentials(twitchId:string, twitchToken:string, streamerId:string):AppAction {

    const args = new Map<string, string>();
    args.set("twitchId", twitchId);
    args.set("twitchToken", twitchToken);
    args.set("streamerId", streamerId);

    const action: AppAction = {
        type: actionTypes.SET_TWITCH_CREDENTIALS,
        args: args,
        companionObj: null
    }
    return action;
}

export function setBackendInfo(backendInfo:BackendInfo):AppAction {
    const args = new Map<string, string>();

    const action: AppAction = {
        type: actionTypes.SET_BACKEND_INFO,
        args: args,
        companionObj: backendInfo
    }
    return action;
}

export function setAsyncOpInProgress(asyncOpInProgress:boolean):AppAction {

    const args = new Map<string, string>();
    args.set("asyncOpInProgress", asyncOpInProgress.toString());

    const action: AppAction = {
        type: actionTypes.SET_GLOBAL_ASYNC_OP_IN_PROGRESS,
        args: args,
        companionObj: null
    }
    return action;
}

export function setGameId(gameId:string):AppAction {

    const args = new Map<string, string>();
    args.set("gameId", gameId);

    const action: AppAction = {
        type: actionTypes.SET_GAME_ID,
        args: args,
        companionObj: null
    }
    return action;
}

export function setAppViewState(appViewState:AppViewState):AppAction {
    const args = new Map<string, string>();
    args.set("appViewState", appViewState.toString());

    const action: AppAction = {
        type: actionTypes.SET_APP_VIEW_STATE,
        args: args,
        companionObj: null
    }
    return action;
}

export function setTestViewState(appViewState:AppViewState):AppAction {

    const action: AppAction = {
        type: actionTypes.SET_TEST_VIEW_STATE,
        args: new Map<string, string>(),
        companionObj:null
    }
    return action;
}

export function serverInformedAboutUpdate():AppAction {

    const args = new Map<string, string>();

    const action: AppAction = {
        type: actionTypes.SEVER_INFORMED_ABOUT_UPDATE,
        args: args,
        companionObj: null
    }
    return action;
}

export function startedFetchingUpdateFromServer():AppAction {

    const args = new Map<string, string>();

    const action: AppAction = {
        type: actionTypes.STARTED_FETCHING_SERVER_UPDATE,
        args: args,
        companionObj: null
    }
    return action;
}