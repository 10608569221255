enum AppViewState {
    StartUp,
    BackendSet,
    TwitchControlObjSet,
    TwitchCredentialsSet,
    GameIdSet,
    Login,
    GameInProgress,
    Test
}

export default AppViewState;