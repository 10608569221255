export class TwitchCredentials {
    public viewerTwitchId:string = "";
    public token: string = "";
    public streamerId:string ="";

    constructor(viewerTwitchId:string, token:string, streamerId:string) {
        this.viewerTwitchId = viewerTwitchId;
        this.token = token;
        this.streamerId = streamerId;
    }
}